<template>
  <div class="signin-form form view-login-form">
    <form v-if="!isHiddenSignin">
      <div class="signin-block__btns-logo">
        <div class="signin-block__btns">
          <button v-on:click="isHiddenSignin = false;
                    isHiddenResetPassword = true; 
                    isHiddenAddSmsCode = true; 
                    isHiddenSendToEmail = true;
                    isHiddenChangePassword= true;
                    activeSignin = true;
                    activeResetPassword = false"
                  type="button"
                  class="btn pb-2 pr-3 pl-0 mr-2 signin-block__btn">
            {{ $t('signin-form.title') }}
          </button>
        </div>
      </div>
      <form-group :field="$v.phone" :label="$t('signin-form.phone-label')" name="phone">
        <el-input
            :placeholder="$t('signin-form.phone-placeholder')"
            v-model="phone"
            v-mask="'+7 (###) ### ##-##'"
            @input="enforcePhoneFormat"
            @blur="onGetIin"
            autocomplete="off"
        >
        </el-input>
      </form-group>
      <form-group @submit="submit(account)" :field="$v.account.iin" :label="$t('signin-form.member-label')" name="iin">
        <el-select v-model="account.iin" :placeholder="$t('signin-form.member-placeholder')" :loading="iinLoading"
                   class="w-100">
          <el-option
              v-for="iin in iinArray"
              @change="$v.account.iin.$touch()"
              :key="iin.value"
              :label="iin.label"
              :value="iin.value">
          </el-option>
        </el-select>
      </form-group>
      <form-group :field="$v.account.password" :label="$t('signin-form.password-label')" name="password">
        <el-input
            onkeyup="value=value.replace(/[^\d]/g,'')"
            type="number"
            maxlength="4"
            :placeholder=" $t('signin-form.password-placeholder')"
            v-model="account.password"
            @input="$v.account.password.$touch()"
            @keypress.enter.prevent="submit(account)"
            autocomplete="off"
            show-password>
        </el-input>
      </form-group>
      <div class="row form-group mt-2 mb-0">
        <div class="col-6">
          <el-checkbox class="signin-form-remember">{{ $t('signin-form.remember-me') }}</el-checkbox>
        </div>
        <div class="col-6 text-center">
          <router-link class="signin-block-forget" to="/forget-password" tag="a" style="text-decoration: none"
                       :disabled="iinLoading">{{ $t('signin-form.forget-password') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <router-link to="/signup" tag="el-link" style="text-decoration: none" class="signin-block__link">
            {{ $t('signin-form.dont-have-account') }} <span
              style="text-decoration: underline; color: #18A0FB"><i>{{ $t('signin-form.signup') }}</i></span>
          </router-link>
        </div>
      </div>
      <div class="text-center pt-3">
        <el-button class="signin-button" native-type="submit" @click.prevent="submit(account)" :loading="loading">
          {{ $t('signin-form.signin-button') }}
        </el-button>
      </div>
    </form>

    <form v-if="!isHiddenResetPassword">
      <div class="signin-block__btns-logo">
        <div class="signin-block__btns">
          <button
              :class="{ activeSign: activeResetPassword }"
              type="button"
              class="btn pb-2 pl-0 signin-block__btn">
            {{ $t('reset-password.title') }}
          </button>
        </div>
        <img src="/images/Qabilet-Final-logo.svg" width="74" height="28">
      </div>
      <div class="reset-password-block">
        <div>
          {{ $t('reset-password.password-recovery-method') }}
        </div>
        <div class="form-check pt-3 pl-2" v-on:click="isHiddenEmail = false; isHiddenSms = true">
          <label class="form-check-label" for="all">
            {{ $t("reset-password.recovery-via-email") }}
            <input class="form-check-input" type="radio" name="filter" id="all" value="0" checked>
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="form-check pb-3 pl-2" v-on:click="isHiddenEmail = true; isHiddenSms = false">
          <label class="form-check-label" for="firstPart">
            {{ $t("reset-password.recovery-via-sms") }}
            <input class="form-check-input" type="radio" name="filter" id="firstPart" value="1">
            <span class="checkmark"></span>
          </label>
        </div>

        <div v-if="!isHiddenEmail">
          <form-group :field="$v.email"
                      :label="$t('reset-password.email-label')"
                      name="email"
                      :class="{'text-danger': !$v.email.email || $v.email.$dirty&&!$v.email.required}">
            <el-input
                id="email"
                :placeholder="$t('reset-password.email-placeholder')"
                v-model="email"
                @change="$v.email.$touch()"
                clearable>
            </el-input>
            <!-- <p v-if="!$v.email.email" class="help-block text-danger">{{ $t('form-group.email-required') }}</p> -->
            <!-- <p v-if="$v.email.$dirty&&!$v.email.required" class="help-block text-danger">{{ $t('form-group.required') }}</p> -->
          </form-group>
          <div class="text-center pt-3">
            <el-button class="signin-button" @click="sendConfirmation"
                       v-on:click="isHiddenSignin = true; isHiddenResetPassword = true; isHiddenAddSmsCode = true; isHiddenSendToEmail = false">
              {{ $t('reset-password.next-btn') }}
            </el-button>
          </div>
        </div>

        <div v-if="!isHiddenSms">
          <form-group
              :field="$v.resetPhone"
              :label="$t('signin-form.phone-label')"
              name="resetPhone">
            <el-input
                :placeholder="$t('signin-form.phone-placeholder')"
                v-model="resetPhone"
                v-mask="'+7 (###) ### ##-##'"
                @input="enforcePhoneFormat"
                @blur="onGetIin"
                autocomplete="off">
            </el-input>
          </form-group>
          <div class="text-center pt-3">
            <el-button class="signin-button"
                       v-on:click="isHiddenSignin = true; isHiddenResetPassword = true; isHiddenSendToEmail = true; isHiddenAddSmsCode = false">
              {{ $t('reset-password.next-btn') }}
            </el-button>
          </div>
        </div>
      </div>
    </form>

    <div v-if="!isHiddenAddSmsCode">
      <div class="signin-block__btns-logo">
        <div class="signin-block__btns">
          <button
              :class="{ activeSign: activeResetPassword }"
              type="button"
              class="btn pb-2 pl-0 signin-block__btn">
            {{ $t('reset-password.title') }}
          </button>
        </div>
        <img src="/images/Qabilet-Final-logo.svg" width="74" height="28">
      </div>
      <div class="reset-password-block">
        <form-group>
          <label>{{ $t('reset-password.enter-sms-code') }}</label>
          <el-input
              :placeholder="$t('reset-password.enter-sms-code-placeholder')"
              v-model="code"
              autocomplete="off">
          </el-input>
        </form-group>
        <div class="text-center pt-3">
          <el-button @click="verification" class="signin-button"
                     v-on:click="isHiddenSignin = true; isHiddenResetPassword = true; isHiddenSendToEmail = true; isHiddenAddSmsCode = true; isHiddenChangePassword = false">
            {{ $t('reset-password.next-btn') }}
          </el-button>
        </div>
      </div>
    </div>

    <div v-if="!isHiddenSendToEmail">
      <div class="signin-block__btns-logo">
        <div class="signin-block__btns">
          <button
              :class="{ activeSign: activeResetPassword }"
              type="button"
              class="btn pb-2 pl-0 signin-block__btn">
            {{ $t('reset-password.title') }}
          </button>
        </div>
        <img src="/images/Qabilet-Final-logo.svg" width="74" height="28">
      </div>
      <div class="reset-password-block pb-3"
           v-html="$t('reset-password.send-to-email')">
      </div>
    </div>

    <div v-if="!isHiddenChangePassword">
      <div class="signin-block__btns-logo">
        <div class="signin-block__btns">
          <button
              :class="{ activeSign: activeResetPassword }"
              type="button"
              class="btn pb-2 pl-0 signin-block__btn">
            {{ $t('reset-password.title') }}
          </button>
        </div>
        <img src="/images/Qabilet-Final-logo.svg" width="74" height="28">
      </div>
      <div>
        <div class="requirements-title">
          {{ $t("reset-password.basic-requirements") }}
        </div>
        <div class="requirements-block">
          <img src="/images/done.svg" width="15" height="15">
          <div class="requirements-desc">{{ $t("reset-password.minimum-symbols") }}</div>
        </div>
        <div class="requirements-block">
          <img src="/images/done.svg" width="15" height="15">
          <div class="requirements-desc">{{ $t("reset-password.number-required") }}</div>
        </div>
        <div class="requirements-block">
          <img src="/images/done.svg" width="15" height="15">
          <div class="requirements-desc">{{ $t("reset-password.uppercase-required") }}</div>
        </div>
        <div class="requirements-block">
          <img src="/images/done.svg" width="15" height="15">
          <div class="requirements-desc">{{ $t("reset-password.invalid-characters") }}</div>
        </div>
      </div>
      <div class="reset-password-block pt-4">
        <div class="form">
          <form @submit.prevent="changePassword">
            <div class="form-group">
              <label class="pwd-lable" for="password">{{ $t("reset-password.new-password-title") }}</label>
              <input type="password" class="form-control" id="password" autocomplete="off" placeholder="**********"
                     v-model="newPassword" minlength="8" required @input="onPasswordInput">
            </div>
            <div class="form-group">
              <label class="pwd-lable" for="password-repeat">{{ $t("reset-password.password-confirm-label") }}</label>
              <input type="password" class="form-control" autocomplete="off" id="password-repeat"
                     @input="onPasswordConfirmInput" v-model="confirmPassword" placeholder="**********" minlength="8"
                     required>
            </div>
            <div class="text-center pt-3">
              <el-button type="submit" id="submit-btn" class="signin-button" :value="$t('reset-password.recovery-btn')">
                {{ $t('reset-password.recovery-btn') }}
              </el-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {required, minLength, email} from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      method: 'email',
      method: 'sms',
      activeSignin: true,
      activeResetPassword: false,
      isHiddenSignin: false,
      isHiddenResetPassword: true,
      isHiddenEmail: false,
      isHiddenAddSmsCode: true,
      isHiddenSendToEmail: true,
      isHiddenSms: true,
      isHiddenChangePassword: true,
      iinLoading: false,
      loading: false,
      phone: '',
      resetPhone: '',
      code: '',
      email: '',
      newPassword: '',
      confirmPassword: '',
      iinArray: [],
      account: {
        phone: '',
        iin: '',
        password: ''
      }
    }
  },
  methods: {
    changePassword() {
      this.loading = true;
      if (this.newPassword !== this.confirmPassword) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Ошибка при обновлении пароля",
          type: 'error'
        });
        return
      }

      this.$http.post(window.API_ROOT + '/api/password/update', {password: this.newPassword})
          .then((res) => {
            Vue.toastr({
              message: this.$t("profile.settings.success"),
              description: this.$t("profile.settings.password-success"),
              type: 'success'
            });
            this.loading = false;
          }).catch((e) => {
        Vue.toastr({
          message: this.$t("profile.settings.error"),
          description: this.$t("profile.settings.password-error"),
          type: 'error'
        });
        this.loading = true;
      });
    },
    addOptionsToSelect() {
      this.iinArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.iin;
        this.iinArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetIin() {
      if (!this.$v.account.phone.$invalid) {
        if (this.account.phone != '') {
          this.iinLoading = true;
          this.getIin({
            phone: this.account.phone
          }).then((res) => {
            this.iinLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.iinLoading = false;
          })
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    submit() {
      this.$v.$touch()
      // if (this.$v.$invalid) return
      this.loading = true;
      this.login(this.account)
          .then(() => {
            this.loading = false;
            sessionStorage.removeItem('children');
            this.$router.push(this.$route.query.redirect || '/profile')
            this.$http.get(window.API_ROOT + '/api/user/edit')
                .then((res) => {
                  localStorage.setItem('user', JSON.stringify(res));
                })
          })
          .catch(() => {
            this.loading = false;
          })
    },
    sendConfirmation() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$http.get(window.API_ROOT + '/api/password/reset/email=' + this.email)
          .then((res) => {
          }).catch((e) => {
      })
    },
    sendSms() {
      this.$v.form.iin.$touch()
      if (this.$v.form.iin.$invalid) return
      this.$http.get(window.API_ROOT + '/api/password/reset/sms?iin=' + this.form.iin)
          .then((res) => {
            if (res.body.status == 'success') {
              Vue.toastr({
                message: 'Success',
                description: this.$t('reset-password.sms-sended'),
                type: 'success'
              });
              this.token = res.body.token;
              this.email = res.body.email;
              this.smsSended = true;
            } else {
              Vue.toastr({
                message: 'Error',
                description: res.body.status,
                type: 'error'
              });
            }
          })
          .catch((e) => {
          })
    },
    verification() {
      this.$http.post(window.API_ROOT + '/api/password/reset/check-sms', {
        code: this.code,
        token: this.token
      })
          .then((res) => {
            if (res.body.status == 'success') {
              Vue.toastr({
                message: 'Success',
                description: this.$t('reset-password.phone-verified'),
                type: 'success'
              });
              this.phoneVerified = true;
            } else {
              Vue.toastr({
                message: 'Error',
                description: res.body.status,
                type: 'error'
              });
            }
          })
          .catch((e) => {

            Vue.toastr({
              message: 'Error',
              description: this.$t('reset-password.verification-error'),
              type: 'error'
            });
          })
    },
    onPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    onPasswordConfirmInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.password_confirmation = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    ...mapActions({
      'getIin': 'getIin',
      'login': 'login'
    })
  },
  validations: {
    email: {email, required},
    phone: {
      required: true,
      minLength: minLength(18)
    },
    account: {
      phone: {
        required: false,
        minLength: minLength(11)
      },
      iin: {
        required: false,
      },
      password: {
        required: false,
        minLength: minLength(4)
      }
    }
  }
}
</script>
<style>
.signin-block {
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 25px 32px 32px 32px;
}

.signin-block__btns-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 35px;
}

.signin-block__btns {
  display: flex;
  flex-direction: row;
}

.signin-block__btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  border: none;
  outline: none;
}

.signin-block__btn:focus {
  box-shadow: inset 0 -1px 0 #fff;
}

.activeSign {
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #31456F 0%, #03C4F7 100%);
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.signin-block__link.el-link.el-link--default:hover {
  color: #303030;
}

.signin-block__link {
  font-size: 12px;
  line-height: 14px;
  color: #303030;
}

.signin-block__link:hover {
  background: transparent !important;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 16px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  /* top: 2px; */
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #18A0FB;
}

.form-check-label input:checked .form-check-label {
  background-color: #18A0FB;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.btn-submit {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}

.btn-submit:hover {
  background: #66b1ff;
  border-color: #66b1ff;
  color: #FFF;
}

.signin-block-forget {
  text-decoration-line: underline;
  font-size: 12px;
  line-height: 14px;
  color: #18A0FB;
  border: none;
  background-color: #fff;
  outline: none;
}

.signin-form-remember .el-checkbox__label {
  line-height: 14px;
  font-size: 12px;
  color: #151515;
}

.signin-form-remember .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #151515 !important;
}

.signin-form .signin-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2C2C2C;
}

.signin-form .el-input__inner {
  font-size: 13px;
  line-height: 15px;
  color: #2c2c2c85;
}

.signin-button, .signin-button:hover {
  background: #18A0FB;
  color: #fff;
  padding: 10px 24px;
  border: none;
}

.reset-password-block {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2C2C2C;
  padding-top: 7px;
}

.requirements-title {
  color: #04C2F4;
  letter-spacing: 0.3px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.requirements-block {
  display: flex;
  flex-direction: row;
  color: #2C2C2C;
  font-size: 11px;
  line-height: 13px;
  align-items: center;
  margin-top: 16px;
  max-width: 296px;
}

.requirements-desc {
  padding-left: 20px;
}

@media screen and (max-width: 678px) {
  .signin-form .el-input__inner {
    font-size: 16px;
  }
}

</style>